import * as React from "react";

import { Helmet } from "react-helmet";

import { backgroundVideo, mainHeroHeader, mainHeroHeaderInner } from './index.module.scss';

import { Button } from "react-bootstrap";

import PageLayout from "../components/PageLayout";
import HeroBanner from "../components/HeroBanner";
import Module from "../components/Module";

import ImageTCX1_1 from "../images/TC_X1_1.png";
import ImageScan from "../images/iStock-475626746.jpg";

import AbstractWavesImage from "../images/iStock-1310435034.jpg";
import SemiconductorMeasuringMachineImage from "../images/iStock-979511540.jpg";

import BackgroundVideo from "../images/iStock-1155634709_x264.mp4"

const IndexPage = () => {
  return (
    <PageLayout>
      <Helmet htmlAttributes={{
        lang: 'en',
      }}>
        <title>Tayos Corp</title>
      </Helmet>

      <div className={mainHeroHeader}>
        <video autoPlay muted loop className={backgroundVideo} id="backgroundVideo">
          <source src={BackgroundVideo} type="video/mp4" />
        </video>
        <div className={mainHeroHeaderInner}>
          <h1 className="display-1">Tayos</h1>
          <p className="lead">
            See under the surface
          </p>
        </div>
      </div>

      <Module image={ImageTCX1_1} imageAlt="Picture of the TC&#8209;X1 electromagnetic conductivity sensor, showing the on/off switch, tare button, display, and coil sensor surface.">
        <h1 className="display-4">TC&#8209;X1 Conductivity Sensor</h1>
        <p className="lead text-muted">
          Meet the world's first non&#8209;contact, calibration&#8209;free electromagnetic&nbsp;conductivity&nbsp;sensor
        </p>
        <p>
          A go-anywhere conductivity sensor that opens up new possibilities, be it concrete, soil, or any kind of sample too unsafe or impractical to examine with 
          a traditional conductivity probe.  The TC&#8209;X1 can get an accurate reading in moments without the need for expensive electrodes or calibration.
        </p>
        <Button href="/cmeter/">Read More</Button>
        <Button variant="outline-secondary" href="/casestudies/">See a Case Study</Button>
      </Module>

      <Module image={ImageScan} imageAlt="CT scan lumbar spine shows severe stenosis and degeration" right>
        <h2 className="display-4">Single-coil Magnetic Induction Tomography</h2>
        <p className="lead text-muted">
          The next must-have medical diagnostic
        </p>
        <p>
          Tayos is the leader in researching and developing single-coil Magnetic Induction Tomography (MIT) technology. MIT is ideally suited for building detailed 
          images of soft tissue using less radiation than a cell phone. Even better, the technology is much cheaper and more portable than existing CT or MRI scanning 
          technology, and uses new physics to detect conditions existing tomography can&apos;t.
        </p>
        <Button variant="secondary" href="/papers/">Read Research</Button>
      </Module>

      <HeroBanner 
          variant="small" 
          backgroundColor="secondary" 
          backgroundImage={AbstractWavesImage} 
          backgroundShift="80%" 
          className="d-flex align-items-center justify-content-center">
        <h1 className="display-4">See Under the Surface</h1>
        <p className="lead">
          Evolving sensing technology and data processing today.<br />Creating life-saving diagnostics and improving crop yields for tomorrow.
        </p>
      </HeroBanner>

      <Module image={SemiconductorMeasuringMachineImage} imageAlt="Connector of Transient Photocurrent in Organic Semiconductor Measuring Machine.">
        <h2 className="display-4">The Future is Almost Here</h2>
        <p className="lead text-muted">
          Using physics, math, and computer know-how in new places
        </p>
        <p>
          Tayos was formed to develop new suites of sensors and pioneer new ways to use existing data sources and devices. 
          We go beyond qualitative and empirical study, and build applications using a complete, quantitative understanding of the physics 
          and math behind sensors and data. With this approach, Tayos is creating technology with applications in diverse fields of study 
          including medicine, civil engineering, and agriculture.
        </p>
        <Button href="/about/">Learn About Tayos</Button>
        <Button variant="outline-secondary" href="/contact/">Contact Us</Button>
      </Module>
    </PageLayout>
  )
}

export default IndexPage
